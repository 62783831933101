body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeaea !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 136, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}

@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*='mobile only']:not(.tablet),
  [class*='tablet hidden'],
  [class*='computer only']:not(.tablet),
  [class*='large screen only']:not(.tablet),
  [class*='widescreen only']:not(.tablet),
  [class*='or lower hidden']:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*='mobile only']:not(.computer),
  [class*='tablet only']:not(.computer),
  [class*='computer hidden'],
  [class*='large screen only']:not(.computer),
  [class*='widescreen only']:not(.computer),
  [class*='or lower hidden']:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*='mobile only']:not([class*='large screen']),
  [class*='tablet only']:not([class*='large screen']),
  [class*='computer only']:not([class*='large screen']),
  [class*='large screen hidden'],
  [class*='widescreen only']:not([class*='large screen']),
  [class*='or lower hidden']:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*='mobile only']:not([class*='widescreen']),
  [class*='tablet only']:not([class*='widescreen']),
  [class*='computer only']:not([class*='widescreen']),
  [class*='large screen only']:not([class*='widescreen']),
  [class*='widescreen hidden'],
  [class*='widescreen or lower hidden'] {
    display: none !important;
  }
}

.search-text {
  text-align: right;
}

@media (max-width: 767px) {
  .search-text {
    text-align: left;
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
